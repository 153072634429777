<template>
	<div>
		<!-- 增加修改 -->
		<ant-modal :visible="open" :modal-title="formTitle" :adjust-size="true" modalHeight="480" modalWidth="640" @cancel="cancel">
			<div slot="content" layout="vertical">
				<!-- 钢网类型 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_text">{{$t('valuation.steel.steel.title1')}}：</div>
					</div>
					<div style="width: 85%;">
						<single-choice :dataIndex="steelMeshTypeIndex" :dataList="dataList.steelMeshType" @select="steelTypeSelect"></single-choice>
					</div>
				</div>
				
				<!-- 钢网规格 -->
				<div style="display: flex;padding-top: 20px;" v-if="steelMeshTypeIndex==0">
					<div class="be_title">
						<div class="be_mandatory">*</div>
						<div class="be_text">{{$t('valuation.steel.steel.title2')}}：</div>
					</div>
					<div style="width: 85%;">
						<el-select style="width: 450px;" v-model="SteelMeshSpecifications" placeholder="">
						    <el-option
						      v-for="item in dataList.steelMeshSpecifications1"
						      :key="item.id"
						      :label="$i18n.locale=='en' ? item.enName : item.name"
						      :value="item.id">
						    </el-option>
						  </el-select>
					</div>
				</div>
				
				<div style="display: flex;padding-top: 20px;" v-if="steelMeshTypeIndex==1">
					<div class="be_title">
						<div class="be_mandatory">*</div>
						<div class="be_text">{{$t('valuation.steel.steel.title2')}}：</div>
					</div>
					<div style="width: 85%;">
						<el-select style="width: 450px;" v-model="SteelMeshSpecifications" placeholder="">
						    <el-option
						      v-for="item in dataList.steelMeshSpecifications2"
						      :key="item.id"
						      :label="$i18n.locale=='en' ? item.enName : item.name"
						      :value="item.id">
						    </el-option>
						  </el-select>
					</div>
				</div>
				
				<div style="display: flex;padding-top: 20px;" v-if="steelMeshTypeIndex==2">
					<div class="be_title">
						<div class="be_mandatory">*</div>
						<div class="be_text">{{$t('valuation.steel.steel.title2')}}：</div>
					</div>
					<div style="width: 85%;">
						<el-select style="width: 450px;" v-model="SteelMeshSpecifications" placeholder="">
						    <el-option
						      v-for="item in dataList.steelMeshSpecifications3"
						      :key="item.id"
						      :label="$i18n.locale=='en' ? item.enName : item.name"
						      :value="item.id">
						    </el-option>
						  </el-select>
					</div>
				</div>
				
				<!-- 纳米处理 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_text">{{$t('valuation.steel.steel.title3')}}：</div>
					</div>
					<div style="width: 85%;">
						<single-choice :dataIndex="nanoprocessing" :dataList="list1" @select="nanoprocessingSelect"></single-choice>
					</div>
				</div>
				
				<!-- 制作方式 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_text">{{$t('valuation.steel.steel.title4')}}：</div>
					</div>
					<div style="width: 85%;">
						<single-choice :dataIndex="smtProductionMethod" :dataList="dataList.steelMeshProductionMethod" @select="smtProductionMethodSelect"></single-choice>
					</div>
				</div>
				
				<!-- 开钢网数量 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_mandatory">*</div>
						<div class="be_text">{{$t('valuation.steel.steel.title5')}}：</div>
					</div>
					<div style="width: 85%;">
						<div style="display: flex;">
							<input class="input1" type="text" v-model="steelMeshNumber" placeholder="" />
							<!-- <span class="x">PCS</span> -->
						</div>
					</div>
				</div>
				
				<!-- MARK需求 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_text">{{$t('valuation.steel.steel.title6')}}：</div>
						<el-popover placement="bottom-start" width="400" trigger="hover"
							:content="$t('valuation.steel.steel.content1')">
							<div slot="reference">
								<i class="el-icon-question"></i>
							</div>
						</el-popover>
					</div>
					<div style="width: 85%;">
						<single-choice :dataIndex="markRequirements" :dataList="dataList.MARKneed" @select="markRequirementsSelect"></single-choice>
					</div>
				</div>
				
				<!-- 钢网用途 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_text">{{$t('valuation.steel.steel.title7')}}：</div>
					</div>
					<div style="width: 85%;">
						<single-choice :dataIndex="steelMeshUsage" :dataList="dataList.steelMeshUsage" @select="steelMeshUsageSelect"></single-choice>
					</div>
				</div>
				
				<!-- 厚度 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_text">{{$t('valuation.steel.steel.title8')}}：</div>
					</div>
					<div style="width: 85%;">
						<single-choice :dataIndex="thicknessIndex" :dataList="dataList.steelMeshThickness" @select="thicknessIndexSelect"></single-choice>
					</div>
				</div>
				
				<!-- 抛光工艺 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_text">{{$t('valuation.steel.steel.title9')}}：</div>
					</div>
					<div style="width: 85%;">
						<single-choice :dataIndex="polishProcessIndex" :dataList="dataList.polishingProcess" @select="polishProcessIndexSelect"></single-choice>
					</div>
				</div>
				
				<!-- 其它需求 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_text">{{$t('valuation.steel.steel.title10')}}：</div>
					</div>
					<div style="width: 85%;">
						<el-input
						  type="textarea"
						  :rows="2"
						  placeholder=""
						  v-model="steelOtherRequirements">
						</el-input>
					</div>
				</div>
				
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_text">发票类型：</div>
					</div>
					<div style="width: 85%;">
						<div style="display: flex;">
							<single-choice :dataIndex="invoiceType" :dataList="dataList.invoiceType"
								@select="selectInvoiceTypeClick"></single-choice>
						</div>
					</div>
				</div>
				
				<div style="display: flex;width: 100%;justify-content: right;padding-top: 20px;" v-if="priceInfo">
					<div>预计总价：</div>
					<div style="color: red;font-weight: bold;">￥{{priceInfo || "0.00"}}</div>
				</div>
				<div style="color: red;display: flex;justify-content: right;padding-top: 10px;">*订单修改成功后，我们将根据最新的参数值重新审核订单</div>
			</div>
			<template slot="footer">
			  <a-button type="primary" @click="submitForm">
			    保存
			  </a-button>
			  <a-button @click="cancel">
			    取消
			  </a-button>
			</template>
		</ant-modal>
	</div>
</template>

<script>
	import modifyModel from './modifyModel'
	export default {
		...modifyModel
	}
</script>

<style lang="less" scoped>
	.be_title{
		display: flex;
		// align-items: center;
		width:20%;
		
		.be_mandatory{
			font-size: 1vw;
			color: red;
		}
		
		.be_text{
			color: #888;
			font-size: 0.8vw;
		}
	}
	
	.input1{
		width: 5vw;
		height: 2vw;
		line-height: 2vw;
		text-align: center;
		border: 1px solid #d6d6d6;
		background: #fff;
	}
	
	.x{
		display: inline-block;
		font-size: 0.8vw;
		border: 1px solid #d6d6d6;
		text-align: center;
		background: #efefef;
		padding: 0 10px;
		display: flex;
		align-items: center;
	}
	
	.popover{
		position: absolute;
		top:32px;
		left: 0;
		width: 400px;
		border: 1px solid #d6d6d6;
		background: #fff;
		padding: 20px;
		z-index: 2;
	}
</style>
