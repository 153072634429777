import AntModal from '@/components/pt/dialog/AntModal' //Modal组件引入
import CButton from '@/components/CButton/index.vue' //button组件引入
import singleChoice from '@/components/singleChoice/index.vue';
import changeSingleChoice from '@/components/changeSingleChoice/index.vue';
import singleChoiceImage from '@/components/singleChoiceImage/index.vue';
import singleChoiceColor from '@/components/singleChoiceColor/index.vue';
import singleChoicePopup from '@/components/singleChoicePopup/index.vue';
import { calcStenPrice } from '@/api/home/index.js' 
import {
	updateOrder
} from '@/api/orderManagement/index'
import {
	dataInJs
} from '@/business/valuation/components/data.js';
export default {
	components: {
		singleChoice,
		changeSingleChoice,
		singleChoiceImage,
		singleChoiceColor,
		singleChoicePopup,
		AntModal,
		CButton
	},
	props: {
		open: { // 按钮默认文本
			type: Boolean,
			default: false
		},
		dataInfo: {
			type: Object,
			default: () => {}
		},
	},
	data() {
		return {
			dataList: dataInJs(), //list数据
			modalOpen: this.open, //弹窗打开关闭
			formTitle: "修改产品", //弹框名
			customStyle: 'background: #fff;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden;',
			steelMeshTypeIndex:0,//钢网类型下标
			SteelMeshSpecifications:1,//钢网规格
			steelMeshNumber:null,//钢网数量
			textarea:null,
			nanoprocessing:0,//纳米处理
			smtProductionMethod:0,//smt制作方式
			markRequirements:0,//MARK需求
			steelMeshUsage:0,//钢网用途
			thicknessIndex:0,//厚度
			polishProcessIndex:0,//抛光工艺
			steelOtherRequirements:null,//其他需求
			invoiceType: 0, //发票类型下标
			list1:[{
				name:"不需要",
				enName:"Not required",
				type:true
			},
			{
				name:"需要",
				enName:"Need",
				type:true
			}],
			priceInfo: null, //订单价格
		}
	},

	created() {

	},

	mounted() {

	},

	watch: {
		open(val) {
			console.log(this.dataInfo)
			this.steelMeshTypeIndex = this.dataInfo.pcbInfoList[0].stentype-1
			this.SteelMeshSpecifications = this.dataInfo.pcbInfoList[0].stenspec
			this.nanoprocessing = this.dataInfo.pcbInfoList[0].stennanometer-1
			this.steelMeshNumber = this.dataInfo.pcbInfoList[0].stencount
			this.smtProductionMethod = this.dataInfo.pcbInfoList[0].stenway-1
			this.steelMeshUsage = this.dataInfo.pcbInfoList[0].stenuse-1
			this.thicknessIndex = this.dataInfo.pcbInfoList[0].stenthick-1
			this.polishProcessIndex = this.dataInfo.pcbInfoList[0].stentechart-1
			this.markRequirements = this.dataInfo.pcbInfoList[0].stenmark-1
			this.steelOtherRequirements = this.dataInfo.pcbInfoList[0].stencilmark
			this.invoiceType = this.dataInfo.invoicetype - 1
			this.calcStenPrice()
		},
		steelMeshNumber(val){
			this.calcStenPrice()
		}
	},

	computed: {

	},

	methods: {
		//------------------------取消按钮-------------------------------
		cancel: function() {
			// console.log("cancel")
			this.modalOpen = false;
			this.$emit('close');
		},

		//----------钢网类型----------------------
		steelTypeSelect:function(index){
			this.steelMeshTypeIndex=index
			// console.log(this.steelMeshTypeIndex)
			this.calcStenPrice()
		},
		
		//-------------纳米处理-----------------------
		nanoprocessingSelect:function(index){
			this.nanoprocessing = index;
			this.calcStenPrice()
		},
		
		//-----------制作方式------------------
		smtProductionMethodSelect:function(index){
			this.smtProductionMethod = index
			this.calcStenPrice()
		},
		
		//------------mark需求-------------------
		markRequirementsSelect:function(index){
			this.markRequirements = index
			this.calcStenPrice()
		},
		
		//----------钢网用途-------------------
		steelMeshUsageSelect:function(index){
			this.steelMeshUsage = index;
			this.calcStenPrice()
		},
		
		//---------厚度下标---------------
		thicknessIndexSelect:function(index){
			this.thicknessIndex = index;
			this.calcStenPrice()
		},
		
		//-------------抛光工艺---------------
		polishProcessIndexSelect:function(index){
			this.polishProcessIndex = index;
			this.calcStenPrice()
		},
		
		//--------------------------发票类型单选回调-----------------------------
		selectInvoiceTypeClick: function(e) {
			this.invoiceType = e;
			this.calcStenPrice()
		},

		//------------------------调用计算价格接口-------------------------
		calcStenPrice: function() {
			let data = {
				stentype: this.steelMeshTypeIndex + 1,
				stenspec: this.SteelMeshSpecifications,
				stennanometer: this.nanoprocessing + 1,
				stenway: this.smtProductionMethod + 1,
				stencount: this.steelMeshNumber,
				stenmark: this.markRequirements + 1,
				stenuse: this.steelMeshUsage + 1,
				stenthick: this.thicknessIndex + 1,
				stentechart: this.polishProcessIndex + 1,
				stencilmark: this.steelOtherRequirements,
			}
			calcStenPrice(data).then(res=>{
				this.priceInfo =res.data
				// console.log("calcStenPrice",res)
			})
		},

		//------------------------提交按钮--------------------------------
		submitForm: function() {
			this.$confirm('是否保存修改的订单？', '修改订单', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let data = {
					pcbInfo: [{
						id: this.dataInfo.pcbInfoList[0].id,
						stentype: this.steelMeshTypeIndex + 1,
						stenspec: this.SteelMeshSpecifications,
						stennanometer: this.nanoprocessing + 1,
						stenway: this.smtProductionMethod + 1,
						stencount: this.steelMeshNumber,
						stenmark: this.markRequirements + 1,
						stenuse: this.steelMeshUsage + 1,
						stenthick: this.thicknessIndex + 1,
						stentechart: this.polishProcessIndex + 1,
						stencilmark: this.steelOtherRequirements,
					}],
					orderInfo: {
						invoicetype: this.invoiceType + 1,
						id: this.dataInfo.id,
						smtfee: this.priceInfo,
						// orderstatus: 0
					}
				}
				updateOrder(data).then(res => {
					this.$message.success(
						'修改成功',
					)
					this.modalOpen = false
					this.$emit('ok')
				})
			}).catch(() => {});
		},
	}
}